.settings-title {
  background: #28313a;
  color: #fff;
  text-transform: uppercase;
  border: none;
}

.settings-title h5 {
  font-size: 20px;
  font-weight: 500;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 34px;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 5px;
  bottom: 4px;
  border-radius: 50%;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #3cb878;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3cb878;
}

input:checked + .slider:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}

.settings-table {
  width: 100%;
  border-collapse: collapse;
}

.settings-table tr {
  width: 100%;
  padding: 0;
  background: #fff;
}

.settings-table tr td {
  width: 50%;
  padding: 1rem;
  font-size: 15px;
  text-align: center;
  border-bottom: 1px solid #ccc;
}
